<template>

   <div class="content">
         <div class="main">
            <div class="main-r">
                <div class="div1">
                    我的评价
                </div>

                <div class="div2">
                    <span class="span1">
                        需求名称
                    </span>
                    <span class="span2">
                        订单金额
                    </span>
                    <span class="span3">
                        订单状态
                    </span>
                    <span class="span4">
                        交易操作
                    </span>
                </div>

                <ul class="ul1">
                    <li>
                        <div class="divt">
                            <span class="span1">2019-01-01</span>
                            <span>19-01-01</span>
                            <span>订单号：jalhdf666</span>
                            <span>南京华航商务</span>

                        </div>
                        <div class="divb">
                            <div class="divb-l">
                                <div class="divb-l2">
                                    <span>此处为需求名称超过15字...</span>
                                </div>
                            </div>
                            <div class="divb-r">
                                <div class="divb-rl">
                                    <p>￥200</p>
                                </div>
                                <div class="divb-rc">
                                    <p class="p1">待支付</p>
                                    <p class="p2">订单详情</p>
                                </div>
                                <div class="divb-rr">
                                    <p class="p1">立即支付</p>
                                    <p class="p2">取消订单</p>
                                </div>
                            </div>
                        </div>
                    </li>
                     <li>
                        <div class="divt">
                            <span class="span1">2019-01-01</span>
                            <span>19-01-01</span>
                            <span>订单号：jalhdf666</span>
                            <span>南京华航商务</span>

                        </div>
                        <div class="divb">
                            <div class="divb-l">
                                <div class="divb-l2">
                                    <span>此处为需求名称超过15字...</span>
                                </div>
                            </div>
                            <div class="divb-r">
                                <div class="divb-rl">
                                    <p>￥200</p>
                                </div>
                                <div class="divb-rc">
                                    <p class="p1">待支付</p>
                                    <p class="p2">订单详情</p>
                                </div>
                                <div class="divb-rr">
                                    <p class="p1">立即支付</p>
                                    <p class="p2">取消订单</p>
                                </div>
                            </div>
                        </div>
                    </li>
                     <li>
                        <div class="divt">
                            <span class="span1">2019-01-01</span>
                            <span>19-01-01</span>
                            <span>订单号：jalhdf666</span>
                            <span>南京华航商务</span>

                        </div>
                        <div class="divb">
                            <div class="divb-l">
                                <div class="divb-l2">
                                    <span>此处为需求名称超过15字...</span>
                                </div>
                            </div>
                            <div class="divb-r">
                                <div class="divb-rl">
                                    <p>￥200</p>
                                </div>
                                <div class="divb-rc">
                                    <p class="p1">待支付</p>
                                    <p class="p2">订单详情</p>
                                </div>
                                <div class="divb-rr">
                                    <p class="p1">立即支付</p>
                                    <p class="p2">取消订单</p>
                                </div>
                            </div>
                        </div>
                    </li>
                     <li>
                        <div class="divt">
                            <span class="span1">2019-01-01</span>
                            <span>19-01-01</span>
                            <span>订单号：jalhdf666</span>
                            <span>南京华航商务</span>

                        </div>
                        <div class="divb">
                            <div class="divb-l">
                                <div class="divb-l2">
                                    <span>此处为需求名称超过15字...</span>
                                </div>
                            </div>
                            <div class="divb-r">
                                <div class="divb-rl">
                                    <p>￥200</p>
                                </div>
                                <div class="divb-rc">
                                    <p class="p1">待支付</p>
                                    <p class="p2">订单详情</p>
                                </div>
                                <div class="divb-rr">
                                    <p class="p1">立即支付</p>
                                    <p class="p2">取消订单</p>
                                </div>
                            </div>
                        </div>
                    </li>
                     <li>
                        <div class="divt">
                            <span class="span1">2019-01-01</span>
                            <span>19-01-01</span>
                            <span>订单号：jalhdf666</span>
                            <span>南京华航商务</span>

                        </div>
                        <div class="divb">
                            <div class="divb-l">
                                <div class="divb-l2">
                                    <span>此处为需求名称超过15字...</span>
                                </div>
                            </div>
                            <div class="divb-r">
                                <div class="divb-rl">
                                    <p>￥200</p>
                                </div>
                                <div class="divb-rc">
                                    <p class="p1">待支付</p>
                                    <p class="p2">订单详情</p>
                                </div>
                                <div class="divb-rr">
                                    <p class="p1">立即支付</p>
                                    <p class="p2">取消订单</p>
                                </div>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        </div>

    </div>

</template>

<script>
    import Vue from 'vue';
    import Aside from "@/components/common/aside.vue";
    import Swiper from 'swiper';
    // import '@/swiper/css/swiper.min.css';

    Vue.filter("fil",function(info){//过滤
			return "共"+ info +"件产品"; //把item这个原本的info数据加上件产品返回出去
		})


    export default {
        name: "index",
        components: { Aside},//注册组件
        data() {
            return {
                radio1: '上海',
                radio2: '上海',
                num1: 5,
                show:true,
                shows:false,
                info:"20",
                activeName:"",
                info1:"全部购买订单",
                flagSelect:false
            }
        },
        methods:{
            showCont(){
                this.show==true,
                this.shows=false
            },
            showConts(){
                this.show==false,
                this.shows=true
            },
            handleClick(){

            },
            btn1(){
                this.flagSelect=true
            }
        },
        mounted() {
            var galleryThumbs = new Swiper('.gallery-thumbs', {
                spaceBetween: 4,
                slidesPerView: 4,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
            });
            var galleryTop = new Swiper('.gallery-top',{
                spaceBetween: 4,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                thumbs: {
                    swiper: galleryThumbs
                }
            });
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/common";
    @mixin icon($m) {
        $url: '../../../assets/image/' + $m;
        @include bgImage($url);
        background-size: contain;
    }
    *{
        margin:0;
        padding:0;
    }
    h2,ul,li{
        margin:0;
        padding:0
    }
    a{
        text-decoration: none;
    }
    li{
        list-style: none;
     border-bottom: 1px solid #ccc;
    }
     .content{
        width: 100%;
        height: 1192px;
        background:#f5f5f5;
        font-size: 15px;
         .main{
            width: 1200px;
            height:1129px;
            margin:0 auto;
            font-size: 15px;
            .div1{
                overflow: hidden;

                .div1-1{
                    margin:20px 0px;
                    .el-tabs__header{
                        margin:0 !important;
                    }
                }
                .ul-top{
                    width: 100%;
                    height:43px;
                    border-bottom: 1px solid #ccc;
                    li{
                        float: left;
                        line-height:43px;
                        padding:0 20px;
                    }
                }
            }

            .main-l{
                width: 181px;
                height: 1129px;
                background:#fff;
                float: left;
            }
             .main-r{
                width: 1000px;
                height: 1129px;
                background: #fff;


                .el-tabs__nav-scroll{
                    margin-top: 20px 0;
                }
                .el-button--primary{
                    width: 134px;
                    height: 30px;
                    border: 1px solid #ccc;
                }
                .ipt1{
                    width: 193px;
                    height: 30px;
                    border: 1px solid #ccc;
                    margin-left: 30px;
                    padding-left:12px;
                    color:#999;

                }
                .btn1{
                    width: 46px;
                    height:30px;
                    margin-left: 18px;
                   background: #0db168;
                    border: 0;
                    color: #fff;
                    border-radius: 2px;
                }



                .div2{
                    width: 962px;
                    height: 50px;
                    font-size: 16px;
                    line-height: 16px;
                    margin:0 auto;
                    margin-top: 23px;
                    height: 43px;
                    background: #f5f5f5;

                    line-height: 44px;
                    .span1{
                        margin-left: 90px;
                    }
                    .span2{
                        margin-left: 470px;
                    }
                    .span3{
                        margin-left: 70px;
                    }
                    .span4{
                        margin-left: 40px;
                    }
                }

                  .ul1{
                      width: 962px;
                      margin:0 auto;
                      margin-top: 20px;
                      border: 1px solid #ccc;
                      li{
                        .divt{
                            width: 100%;
                            height: 44px;
                            background: #f5f5f5;
                            line-height: 44px;
                            color:#666666;
                            span{
                                margin-left: 29px;
                            }
                            .span1{
                            margin-left: 14px;
                            }
                        }
                        .divb{
                            width: 100%;
                            height: 120px;


                            .divb-l{
                                width: 445px;
                                height: 120px;
                                float: left;
                                .divb-l2{
                                    width:100px;
                                    height: 200px;

                                }
                                .divb-l1{
                                    width:82px;
                                    height: 82px;
                                    float: left;

                                    margin-left: 15px;
                                    margin-top: 20px;
                                }

                                .divb-l2{
                                    float: left;
                                    width:212px;
                                    height: 82px;

                                    span{
                                        display: block;
                                        margin-top: 20px;
                                        margin-left: 23px;
                                    }
                                }
                            }
                            .divb-r{
                                float: right;
                                width: 515px;
                                height: 120px;
                                >div{
                                    float: left;
                                }
                                .divb-rl{
                                    width: 237px;
                                    p{

                                        margin-left: 175px;
                                        margin-top: 24px;
                                    }

                                }
                                .divb-rc{

                                    width: 150px;
                                    .p1{
                                        margin-left: 73px;
                                        margin-top: 25px;
                                    }
                                    .p2{
                                        margin-left: 64px;
                                        margin-top: 15px;
                                        color:#343434;

                                    }
                                }
                                .divb-rr{
                                    width: 128px;
                                    font-size: 15px;
                                    .p1{
                                        margin-left: 34px;
                                        margin-top: 18px;
                                        width: 78px;
                                        height: 26px;
                                        border-radius: 3px;
                                       background: #0db168;
                                        color: #fff;
                                        text-align: center;
                                        line-height:26px;
                                    }
                                    .p2{
                                        margin-left: 42px;
                                        margin-top: 16px;
                                    }
                                }
                            }
                        }
                    }
                }



                .div3{
                    padding-left: 30px;
                }
                .div4{
                    width: 960px;
                    border: 1px solid #e5e5e5;
                     margin:0 auto;
                    .div4-1{
                        width: 960px;
                        height:44px;
                        background: #f5f5f5;
                        margin:0 auto;
                        font-size: 15px;
                        line-height: 44px;
                        color: #656565;
                        span{
                            margin-left: 125px;
                        }
                        .span2{
                            margin-left: 250px;
                        }
                        .span3{
                            margin-left: 145px;
                        }
                        .span4{
                            margin-left: 160px;
                        }
                    }
                    .ul2{
                        margin:0 auto;
                        width: 960px;
                            height:120px;

                        li{
                            width: 960px;
                            height:120px;
                            border-bottom: 4px solid #e5e5e5;
                            .ul2-lil{
                                width: 327px;
                                height: 100%;
                                // background: blue;
                                float: left;

                            }
                            .ul2-lir{
                                width: 536px;
                                height: 100%;
                                float:right;
                            }
                        }
                    }

                }
                .div5{
                    width: 960px;
                    margin: 0 auto;
                    height:94px;
                    line-height: 94px;
                    text-align: right;
                        span{
                            margin-right: 30px;
                        }
                        .teshu{
                            color:#fe0036;
                        }
                    .btn1{
                         width: 100px;
                        height:36px;

                        border-radius: 3px;
                        border: none;
                        font-size: 15px;
                        color:#fff;
                    }

                }
            }



        }
    }

</style>
